<template>
  <div class="showcolumn">
    <div class="box">
      <div class="menus">
        <button  @click='links(item,index)' :class='{choice:index==choiceI}' v-for='item,index in nav1Txt' >{{item.BannerName}}</button>
      </div>
      <div class="logins">
        <button  v-if='!this.$store.state.user?true:false' @click='logins'>登录</button>
        <button  v-if='!this.$store.state.user?true:false' @click='registers'>注册</button>
        <p v-if='this.$store.state.user?true:false'>HI~欢迎您，<span class="name">{{this.$store.state.user?this.$store.state.user.ContactName:'null' | formatPhoneHide(1)}}</span><button @click='outlogin' class="outlg">【安全退出】</button></p>
      </div>
    </div>
    <div class="htmls" v-html="txt"></div>
    <div class="likesAuto">
      <likes class='likes'></likes>
      <infiniteImg class='infiniteImg'></infiniteImg>
    </div>
    <register v-show='this.$store.state.regtagger' ></register>
    <login v-show='this.$store.state.logintagger'  ></login>
  </div>
</template>
<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import register from '@/components/register/register.vue';//注册
import login from '@/components/login/login.vue';//登录
import {mixin,usermethod} from '../assets/js/common.js' //公用js
export default {
  name: 'showcolumn',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  mixins: [mixin],//混入
  components: {
    likes,
    infiniteImg,
    login,
    register
  },
  props: {
    msg: String
  },
  data(){
    return {
      htmltitle:'loading...',
      // innerHtml:'21321',
      nav1Txt:null,
      choiceI:null,
      txt:'',
    }
  },
  methods:{
    outlogin(){
      // 退出登录
      this.$store.dispatch("cmtSetUser",null);//赋值
      usermethod({method:'del'});//存本地
      // this.$MessageBox.alert('退出登录成功！',{type:'success'});
      window.location.href='/accountlogin.html'
    },
    logins(){
      // 打开关闭登录
      // this.logintagger = !this.logintagger
      this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
    },
    registers(){
      // 打开关闭注册
      // this.regtagger = !this.regtagger
      this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
    },
    links(item,index){
      // 切换栏目
      if(item.BannerName=='首页'){
        window.location.href=item.HrefLink
        return;
      }
      if(item.HrefLink){
        window.open(item.HrefLink)
      }else{
        this.choiceI = index
        this.txt = this.nav1Txt[this.choiceI].RichText
        this.htmltitle = this.nav1Txt[this.choiceI].BannerName+'-微微定'
        this._router({then:()=>{
          this.$router.push({query:{linkes:index}})
        }})
      }
    },
    getLinks(){
      // 导航栏目组
      this.choiceI = this._spf(this.$route.query.linkes)
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetAllBanner',this.keys())
        .then(res => {
            if(res.data.Success){
              this.$set(this,'nav1Txt',res.data.Data.List)
              this.txt = this.nav1Txt[this.choiceI].RichText
              this.htmltitle = this.nav1Txt[this.choiceI].BannerName+'-微微定'
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // end
    },
    goBack () {
      // 监听浏览器回退键
      this.getLinks();
    }
  },
  mounted(){
    this.$nextTick(()=>{
      if (window.history && window.history.pushState) {
          window.addEventListener('popstate', this.goBack, false);
      }
      // 加载
      var _this =this;
      let b = function() {
        return new Promise(function(resolve, reject) {
            // 导航栏目组
            _this.choiceI = _this._spf(_this.$route.query.linkes)
            _this._axios({then:()=>{
              _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAllBanner',_this.keys())
              .then(res => {
                  if(res.data.Success){
                    _this.$set(_this,'nav1Txt',res.data.Data.List)
                    _this.txt = _this.nav1Txt[_this.choiceI].RichText
                    _this.htmltitle = _this.nav1Txt[_this.choiceI].BannerName+'-微微定'
                    resolve()
                  }else{
                    resolve()
                    _this.$MessageBox.alert(res.data.Error,{type:'error'});
                  }
                }, res => {
                  resolve()
                  // this.$MessageBox.alert('系统异常',{type:'error'});
                })
            }})
            // end
        })
      }
      this._reduce([b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  destroyed(){
    window.removeEventListener('popstate', this.goBack, false);
  }
}
</script>
<style  lang="scss" scoped>
.showcolumn {
  .box {
    width: 1200px;display: flex;justify-content: center;margin:0 auto;height: 40px;position: fixed;top:40px;left:calc((100vw - 1200px)/2);z-index: 9;
    .menus {
      background: rgba(0,0,0,0.03);padding:0 10px;color:#333;display: flex;align-items: center;
      button {
        background: none;font-size: 15px;margin:0 15px;padding:2px 10px;border-radius: 20px;
        &:hover {
          background: #f88288;
          color:#fff;
        }
      }
      .choice {
        background: #f88288;color:#fff;
      }
    }
    .logins{
      position: absolute;top:0px;right:0px;height: 40px;
      .name {
        color:#ed008c;margin-right: 5px;
      }
      button {
        background: none;height: 40px;line-height: 40px;
        &:first-child {
          margin-right: 14px;position: relative;
        }
        &:first-child:after {
          content: '';display: block;position: absolute;width: 1px;height: 14px;background: #333;top:13px;right:-8px;
        }
        &:hover {
          opacity: 0.6;
        }
      }
      .outlg {
        margin-left:5px;
      }
    }
  }
  .htmls {
    padding-bottom:50px;
  }
  .likesAuto {
    width: 1200px;margin:0 auto;margin-top:40px;
    .infiniteImg {
      margin-top:40px;
    }
  }
}
</style>
